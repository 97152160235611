import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import vant from "vant";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "vant/lib/index.css";
import zhCn from "element-plus/es/locale/lang/zh-cn";

import "@/assets/style/index.less";

createApp(App).use(router)
.use(vant)
.use(ElementPlus, {
  locale: zhCn,
})
.mount("#app");
