import { createRouter, createWebHashHistory } from "vue-router";
const routes = [
  {
    path: "/auth",
    component: () => import("../views/gzh-auth.vue"),
    name: "auth",
    meta: {
      title: "统一登录平台",
    },
  },
  {
    path: "/wxauth",
    component: () => import("../views/wx-auth.vue"),
    name: "wxauth",
    meta: {
      title: "统一登录平台",
    },
  },
  {
    path: "/register",
    component: () => import("../views/PhoneRegisterView"),
    name: "Register",
    meta: {
      title: "新用户注册",
    },
  },
  {
    path: "/:port/:platform/:loginStyle",
    component: () => import("../views/JudgeView"),
    name: "choose",
    meta: {
      title: "统一登录平台",
    },
  },
  {
    path: "/pcLogin",
    component: () => import("../views/PcLogin.vue"),
    name: "pcLogin",
    meta: {
      title: "古探长统一登录平台",
    },
  },
  {
    path: "/gtzChangePassword",
    component: () => import("../views/gtzChangePasswordView"),
    name: "gtzChangePassword",
    meta: {
      title: "古探长统一登录平台",
    },
  },
  {
    path: "/gtzForgetPassword",
    component: () => import("../views/gtzForgetPasswordView"),
    name: "gtzForgetPassword",
    meta: {
      title: "古探长统一登录平台",
    },
  },
  {
    path: "/landLogin",
    component: () => import("../views/landBlockPcLogin.vue"),
    name: "landLogin",
    meta: {
      title: "考古项目管理平台",
    },
  },
  {
    path: "/landMobileLogin",
    component: () => import("../views/LandPhoneLoginView.vue"),
    name: "LandPhoneLogin",
    meta: {
      title: "考古项目管理平台",
    },
  },
  {
    path: "/bindPhone",
    component: () => import("../views/gtzPcBindPhoneView.vue"),
    name: "BindPhone",
    meta: {
      title: "古探长统一登录平台",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  next();
});

export default router;
